<template>
    <div>
        <SearchForm ref="searchForm" @onSearch="filterSearch" @onReset="resetSearchData" />
        <div v-if="userData === null" class="overflow-x-auto bg-white mt-2 rounded-lg">
            <Table :users="users" @onSelectUser="selectUser" />
        </div>
        <div v-else class="bg-white mt-2 rounded-lg">
            <div class="flex p-3 border-b">
                <h3 class="py-2 flex-grow text-center mx-2">{{ userData.name }}</h3>
                <btn
                    color="border border-black"
                    size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                    customClass="flex-shrink"
                    @onClick="userData = null">
                    重選對象
                </btn>
            </div>
            <div class="text-center p-3">
                <TagSelect ref="tag" :userData="userData" />
            </div>
        </div>
    </div>
</template>

<script>
// 選擇 tag 組建
import TagSelect from "@/components/TagSelect.vue";
// user 列表 table
import Table from "./Table.vue";
// 搜尋表單
import SearchForm from "./SearchForm.vue";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";
export default {
    components: {
        SearchForm,
        Btn,
        Table,
        TagSelect,
    },
    data() {
        return {
            // 發送名單
            users: [],
            // 搜尋條件
            filterData: {},
            loading: false,
            userData: null,
        };
    },
    methods: {
        /**
         * 選擇到的使用者資料
         * @param { type Object(物件) } userData 使用者資料
         */
        selectUser(userData) {
            this.userData = userData;
            this.$nextTick(() => {
                this.$refs.tag.setDefault();
            });
        },
        /**
         * @param { tpye Object(物件) } filterData 搜尋過濾資料
         */
        filterSearch(filterData) {
            this.filterData = {};
            this.filterData = { ...filterData, page: 1, limit: 1000000 };
            // 判斷沒有傳送 指定身份值時 預設給予 roles = [1,2] 此種身份代表服務商
            if (this.filterData.roles === undefined) {
                this.filterData["roles"] = [1, 2];
            }
            this.getLists({
                ...this.filterData,
            });
        },
        /**
         * @param { tpye Object(物件) } filterData 搜尋過濾資料
         */
        async getLists(filterData) {
            this.loading = true;
            try {
                const { data } = await this.$api.GetProviderListApi(filterData);
                this.users = data.data;
                this.loading = false;
            } catch (err) {
                this.loading = false;
                this.$message({
                    type: "error",
                    message: "取得服務商資料失敗",
                });
            }
        },
        // 重置搜尋資料
        resetSearchData() {
            this.fileList = [];
            this.users = [];
            this.userData = null;
        },
        handleChange(value, direction, movedKeys) {
            console.log(value, direction, movedKeys);
            this.right = value;
            this.left = movedKeys;
        },
    },
};
</script>

<template>
    <div>
        <div class="flex justify-center">
            <el-transfer
                v-model="value"
                style="text-align: left; display: inline-block"
                :left-default-checked="left"
                :right-default-checked="right"
                :titles="['標記選項', '已選擇標記']"
                :button-texts="['取消標記', '新增標記']"
                :format="{
                    noChecked: '${total}',
                    hasChecked: '${checked}/${total}',
                }"
                :data="datas"
                @change="handleChange">
                <span slot-scope="{ option }">{{ option.name }}</span>
                <!-- <el-button slot="left-footer" class="transfer-footer" size="small">操作</el-button> -->
                <!-- <el-button slot="right-footer" class="transfer-footer" size="small" @click="sendNote">送出標記</el-button> -->
            </el-transfer>
        </div>
        <div class="flex justify-center mt-5">
            <btn v-permission="['update']" @onClick="sendNote"> 送出標記 </btn>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Btn from "@/components/Button.vue";
export default {
    components: {
        Btn,
    },
    props: {
        // 選擇到的使用者資料
        userData: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {},
    data() {
        return {
            datas: [],
            // 預設頁面
            currentPage: 0,
            // 分頁資料
            paginationData: {
                limit: 15,
                total: 0,
            },
            loading: false,
            right: [],
            left: [],
            value: [],
        };
    },
    methods: {
        ...mapActions("apiStore", ["errorCallback"]),
        handleChange(value, direction, movedKeys) {
            console.log(value, direction, movedKeys);
            this.right = value;
            this.left = movedKeys;
        },
        async sendNote() {
            try {
                await this.$api.UpdateUserTagsApi(this.userData.id, this.right);
                this.$message({
                    type: "success",
                    message: `更新標籤成功`,
                });
            } catch (err) {
                this.errorCallback({ err });
            }
        },
        /**
         * 取得列表資料
         * @param { type Object(物件) } filterData 搜尋條件
         * @param { type Boolean(布林) } isDefaultSorted 判斷是否用預設排序
         */
        async getLists(filterData) {
            this.loading = true;
            // 判斷沒有傳入 sort_type 值時 預設為倒序
            if (!Object.prototype.hasOwnProperty.call(filterData, "sort_type")) {
                filterData["sort_type"] = "desc";
            }
            // 判斷沒有傳入 sort_by 值時 預設為 created_at
            if (!Object.prototype.hasOwnProperty.call(filterData, "sort_by")) {
                filterData["sort_by"] = "created_at";
            }
            try {
                const { data } = await this.$api.GetTagListApi({ ...filterData, limit: 100000 });
                this.loading = false;
                this.currentPage = data.current_page;
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };

                this.datas = data.data.map((item) => ({ key: item.id, name: item.name }));
            } catch (err) {
                console.log(err);
                this.loading = false;
                this.$message({
                    type: "error",
                    message: "取得資料失敗",
                });
            }
        },
        setDefault() {
            this.$nextTick(() => {
                this.value = this.userData.tags.map((tag) => tag.id);
                // 因為 dom 延遲產生關西 因此需多等待 1秒鐘後在 把右側 tag 選項給予選取狀態
                setTimeout(() => {
                    this.right = this.userData.tags.map((tag) => tag.id);
                }, 1000);
            });
        },
    },

    async mounted() {
        await this.getLists({});
    },
};
</script>

<template>
    <table class="myTable">
        <thead>
            <tr>
                <th>#</th>
                <th>暱稱</th>
                <th>真實姓名</th>
                <th>手機</th>
                <th class="w-[100px] min-w-[100px] cursor-pointer">評分</th>
                <th>註冊日期</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in users" :key="index" class="text-center duration-200 transition">
                <td><input v-model="userData" type="radio" :value="item" @change="$emit('onSelectUser', userData)" /></td>
                <td>{{ $subString(item.name, 10) }}</td>
                <td>
                    {{ !$isEmpty(item.real_name) ? $subString(item.real_name, 10) : item.real_name }}
                </td>
                <td>{{ item.phone }}</td>
                <td>{{ item.rating_score }}</td>
                <td>
                    {{ $moment(item.created_at).format("YYYY/MM/DD") }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: {
        users: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            userData: null,
        };
    },
};
</script>
